import React, { useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { config } from "../config";
import { getCache } from "./apolloCache";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { typeDefs } from "./localSchema";

const httpLink = new HttpLink({
  uri: config.apiUrl,
});

const wsLink = new WebSocketLink({
  uri: config.websocketUrl,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const MyApolloProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      const cache = getCache();
      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: "write",
      });

      await newPersistor.restore();
      setPersistor(newPersistor);

      const apolloClient = new ApolloClient({
        link: splitLink,
        cache,
        typeDefs,
        connectToDevTools: true,
      });
      setClient(apolloClient);
    }
    init().catch(console.error);
  }, []);

  if (!client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
