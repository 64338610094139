const mapboxToken =
  "pk.eyJ1IjoiZWxpeWFodWQiLCJhIjoiY2t3am1sdTJlMTBibjJ1bzA3cW4yaWRleSJ9.pSjAa017S2YxuT6tm1UgQA";

const localConfig = {
  apiUrl: "http://localhost:3000/graphql",
  websocketUrl: "ws://localhost:3000/subscriptions",
  mapboxToken,
};

const prodConfig = {
  apiUrl: "https://api.builder.iotgps.io/graphql",
  websocketUrl: "wss://api.builder.iotgps.io/subscriptions",
  mapboxToken,
};

function getConfig() {
  if (window.location.origin.match(/http:\/\/localhost/)) {
    return localConfig;
  } else {
    return prodConfig;
  }
}

export const config = getConfig();

