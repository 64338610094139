import { Order } from "../../generated/graphql";
import { Marker, Popup } from "react-leaflet";
import React from "react";

import svgData from "../../assets/coffee-shop.png";
import L from "leaflet";

let icon = L.icon({
  iconUrl: svgData,
  iconSize: [40, 40],
});

const OrderRecipientMarker = ({ order }: { order: Order }) => {
  return (
    <Marker
      icon={icon}
      position={[
        order.delivery.location.latitude,
        order.delivery.location.longitude,
      ]}
    >
      <Popup>Delivery for {order.customer.name}</Popup>
    </Marker>
  );
};

export default OrderRecipientMarker;
