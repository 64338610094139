import { Order } from "../../generated/graphql";
import React from "react";
import OrderRecipientMarker from "./OrderRecipientMarker";
import DeliveryVehicleMarker from "./DeliveryVechileMarker";
import { useReactiveVar } from "@apollo/client";
import { selectedOrderVar } from "../../lib/apollo/apolloCache";
import useFetch from "use-http";
import { Polyline } from "react-leaflet";
import { config } from "../../lib/config";

const useDirections = (order: Order) => {
  const body = `coordinates=${order.delivery.device?.currentStatus?.longitude}%2C${order.delivery.device?.currentStatus?.latitude}%3B${order.delivery.location.longitude}%2C${order.delivery.location.latitude}&steps=true&waypoints=0%3B1&waypoint_names=Home%3BWork`;
  const { data } = useFetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving?access_token=${config.mapboxToken}`,
    {
      method: "POST",
      headers: {
        "Content-Type": `application/x-www-form-urlencoded`,
      },
      body,
    },
    [order]
  );

  if (!data) return null;

  return (
    data?.routes[0]?.legs[0]?.steps.map((step: any) => {
      const location = step?.maneuver?.location;
      return [location[1], location[0]];
    }) ?? null
  );
};

const Directions = ({ order }: { order: Order }) => {
  const directions = useDirections(order);

  if (!directions) {
    return null;
  }

  return (
    <>
      <Polyline pathOptions={{ color: "blue" }} positions={directions} />
    </>
  );
};

const OrderElements = ({ order }: { order: Order }) => {
  const selectedOrder = useReactiveVar(selectedOrderVar);
  const isSelected = selectedOrder === order;

  return (
    <>
      <OrderRecipientMarker order={order as Order} key={order.id} />
      <DeliveryVehicleMarker
        order={order as Order}
        key={order.delivery.device?.imei}
      />
      {isSelected && <Directions order={order} />}
    </>
  );
};

export default OrderElements;
