import React, { useState } from "react";
import { Device, useGetDevicesQuery } from "../../generated/graphql";
import styled from "styled-components";

interface DeviceCardParams {
  device: Device;
}

const DeviceCardWrapper = styled.div`
  border: 1px solid grey;
  margin: 20px;
  padding: 20px;
`;

export const DeviceCard = ({ device }: DeviceCardParams) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <DeviceCardWrapper
      style={{ backgroundColor: device.color ?? "white" }}
      onClick={() => setShowDetails(true)}
    >
      {device.name}
    </DeviceCardWrapper>
  );
};

const DeviceListWrapper = styled.div`
  overflow-y: auto;
`;

interface DeviceListParams {}

export const DeviceList = ({}: DeviceListParams) => {
  const { data } = useGetDevicesQuery({
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore"
  });
  console.log('devices', data?.devices)

  return (
    <DeviceListWrapper>
      <h2>All Vehicles</h2>
      {data?.devices?.map((device) => (
        <DeviceCard device={device as Device} key={device?.imei} />
      ))}
    </DeviceListWrapper>
  );
};
