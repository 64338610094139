import React from "react";
import styled from "styled-components";
import { Order, useGetOrdersQuery } from "../../generated/graphql";
import { OrderCard } from "./OrderCard";

interface OrderListParams {}

const OrderListWrapper = styled.div``;

export const OrderList = ({}: OrderListParams) => {
  const { data, loading } = useGetOrdersQuery({
    fetchPolicy: "cache-and-network",
  });

  return (
    <OrderListWrapper>
      <h2>Orders</h2>
      {data?.orders?.map(
        (order) => order && <OrderCard order={order as Order} key={order.id} />
      )}
    </OrderListWrapper>
  );
};
