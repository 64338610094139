/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** An object that links a geometry to properties in order to provide context. */
  GeoJSONFeature: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: any;
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: any;
};

export type Alert = {
  __typename?: 'Alert';
  address?: Maybe<Scalars['String']>;
  alertTypeId: Scalars['Int'];
  imei?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Latitude']>;
  lon?: Maybe<Scalars['Longitude']>;
  messageId: Scalars['ID'];
  sendTime?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type Boundary = {
  __typename?: 'Boundary';
  boundaryId: Scalars['ID'];
  color?: Maybe<Scalars['HexColorCode']>;
  geometry?: Maybe<Scalars['GeoJSONFeature']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Delivery = {
  __typename?: 'Delivery';
  device?: Maybe<Device>;
  deviceId?: Maybe<Scalars['String']>;
  location: Location;
  person: Person;
};

export type Device = {
  __typename?: 'Device';
  brand?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['HexColorCode']>;
  createdBy?: Maybe<Scalars['String']>;
  currentStatus?: Maybe<DeviceCurrentStatus>;
  description?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['Int']>;
  deviceStatus?: Maybe<DeviceStatus>;
  deviceTypeId?: Maybe<Scalars['Int']>;
  forward?: Maybe<Scalars['Boolean']>;
  iccid?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  imei: Scalars['ID'];
  lastMessages?: Maybe<Array<Message>>;
  model?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type DeviceLastMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type DeviceCurrentStatus = {
  __typename?: 'DeviceCurrentStatus';
  gpsUTCTime?: Maybe<Scalars['String']>;
  ignition?: Maybe<IgnitionType>;
  imei?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Latitude']>;
  longitude?: Maybe<Scalars['Longitude']>;
  message_id: Scalars['ID'];
  odoMileage?: Maybe<Scalars['Float']>;
  sendTime?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type DeviceStatus = {
  __typename?: 'DeviceStatus';
  BatteryAlertInAlarm?: Maybe<Scalars['String']>;
  SpeedAlertInAlarm?: Maybe<Scalars['String']>;
  TripDistanceInAlarm?: Maybe<Scalars['String']>;
  TripDurationInAlarm?: Maybe<Scalars['String']>;
  TripStopDurationInAlarm?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  azimuth?: Maybe<Scalars['String']>;
  batteryPercentage?: Maybe<Scalars['String']>;
  cellId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['Int']>;
  gpsAccuracy?: Maybe<Scalars['String']>;
  gpsUTCTime?: Maybe<Scalars['String']>;
  imei: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  odoMileage?: Maybe<Scalars['String']>;
  reportId?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
  sendTime?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
  tripGenerator?: Maybe<Scalars['String']>;
  tripId?: Maybe<Scalars['String']>;
  tripTriggerCount?: Maybe<Scalars['Int']>;
};

export type GetMessagesForDeviceParams = {
  endDate?: InputMaybe<Scalars['String']>;
  imei: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ResponseOrder>;
  startDate: Scalars['String'];
};

export enum IgnitionType {
  Off = 'Off',
  On = 'On'
}

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  latitude: Scalars['Latitude'];
  longitude: Scalars['Longitude'];
};

export type Message = {
  __typename?: 'Message';
  gpsUTCTime?: Maybe<Scalars['String']>;
  ignition?: Maybe<IgnitionType>;
  imei?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Latitude']>;
  longitude?: Maybe<Scalars['Longitude']>;
  message_id: Scalars['ID'];
  odoMileage?: Maybe<Scalars['Float']>;
  sendTime?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  markAlertAsRead?: Maybe<Alert>;
};


export type MutationMarkAlertAsReadArgs = {
  messageId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['String'];
  customer: Customer;
  delivery: Delivery;
  id: Scalars['ID'];
  product: Product;
  scheduledFor: Scalars['String'];
};

export type PaginatedMessagesResponse = {
  __typename?: 'PaginatedMessagesResponse';
  items?: Maybe<Array<Message>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Person = {
  __typename?: 'Person';
  image: Scalars['String'];
  name: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allAlerts: Array<Maybe<Alert>>;
  boundaries?: Maybe<Array<Maybe<Boundary>>>;
  device?: Maybe<Device>;
  devices?: Maybe<Array<Device>>;
  messages?: Maybe<Array<Message>>;
  messagesForDevice?: Maybe<PaginatedMessagesResponse>;
  order?: Maybe<Order>;
  orders?: Maybe<Array<Order>>;
};


export type QueryDeviceArgs = {
  imei: Scalars['String'];
};


export type QueryMessagesForDeviceArgs = {
  params: GetMessagesForDeviceParams;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export enum ResponseOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  alertReceived?: Maybe<Alert>;
  deviceCurrentStatusUpdated?: Maybe<DeviceCurrentStatus>;
};


export const GetAllAlertsDocument = gql`
    query GetAllAlerts {
  allAlerts @client {
    alertTypeId
    sendTime
    messageId
    imei
    lat
    lon
    address
    value
    isRead
  }
}
    `;

/**
 * __useGetAllAlertsQuery__
 *
 * To run a query within a React component, call `useGetAllAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAlertsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAlertsQuery, GetAllAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAlertsQuery, GetAllAlertsQueryVariables>(GetAllAlertsDocument, options);
      }
export function useGetAllAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAlertsQuery, GetAllAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAlertsQuery, GetAllAlertsQueryVariables>(GetAllAlertsDocument, options);
        }
export type GetAllAlertsQueryHookResult = ReturnType<typeof useGetAllAlertsQuery>;
export type GetAllAlertsLazyQueryHookResult = ReturnType<typeof useGetAllAlertsLazyQuery>;
export type GetAllAlertsQueryResult = Apollo.QueryResult<GetAllAlertsQuery, GetAllAlertsQueryVariables>;
export function refetchGetAllAlertsQuery(variables?: GetAllAlertsQueryVariables) {
      return { query: GetAllAlertsDocument, variables: variables }
    }
export const MarkAlertAsReadDocument = gql`
    mutation MarkAlertAsRead($messageId: String!) {
  markAlertAsRead(messageId: $messageId) @client {
    alertTypeId
    sendTime
    messageId
    imei
    lat
    lon
    address
    value
    isRead
  }
}
    `;
export type MarkAlertAsReadMutationFn = Apollo.MutationFunction<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>;

/**
 * __useMarkAlertAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAlertAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAlertAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAlertAsReadMutation, { data, loading, error }] = useMarkAlertAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMarkAlertAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>(MarkAlertAsReadDocument, options);
      }
export type MarkAlertAsReadMutationHookResult = ReturnType<typeof useMarkAlertAsReadMutation>;
export type MarkAlertAsReadMutationResult = Apollo.MutationResult<MarkAlertAsReadMutation>;
export type MarkAlertAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAlertAsReadMutation, MarkAlertAsReadMutationVariables>;
export const GetBoundariesDocument = gql`
    query GetBoundaries {
  boundaries {
    boundaryId
    name
    geometry
    color
  }
}
    `;

/**
 * __useGetBoundariesQuery__
 *
 * To run a query within a React component, call `useGetBoundariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoundariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoundariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBoundariesQuery(baseOptions?: Apollo.QueryHookOptions<GetBoundariesQuery, GetBoundariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoundariesQuery, GetBoundariesQueryVariables>(GetBoundariesDocument, options);
      }
export function useGetBoundariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoundariesQuery, GetBoundariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoundariesQuery, GetBoundariesQueryVariables>(GetBoundariesDocument, options);
        }
export type GetBoundariesQueryHookResult = ReturnType<typeof useGetBoundariesQuery>;
export type GetBoundariesLazyQueryHookResult = ReturnType<typeof useGetBoundariesLazyQuery>;
export type GetBoundariesQueryResult = Apollo.QueryResult<GetBoundariesQuery, GetBoundariesQueryVariables>;
export function refetchGetBoundariesQuery(variables?: GetBoundariesQueryVariables) {
      return { query: GetBoundariesDocument, variables: variables }
    }
export const GetDevicesDocument = gql`
    query GetDevices {
  devices {
    imei
    name
    description
    currentStatus {
      imei
      latitude
      longitude
      speed
    }
  }
}
    `;

/**
 * __useGetDevicesQuery__
 *
 * To run a query within a React component, call `useGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDevicesQuery(baseOptions?: Apollo.QueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
      }
export function useGetDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
        }
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>;
export type GetDevicesLazyQueryHookResult = ReturnType<typeof useGetDevicesLazyQuery>;
export type GetDevicesQueryResult = Apollo.QueryResult<GetDevicesQuery, GetDevicesQueryVariables>;
export function refetchGetDevicesQuery(variables?: GetDevicesQueryVariables) {
      return { query: GetDevicesDocument, variables: variables }
    }
export const DeviceCurrentStatusUpdatedDocument = gql`
    subscription DeviceCurrentStatusUpdated {
  deviceCurrentStatusUpdated {
    imei
    message_id
    sendTime
    latitude
    longitude
    speed
  }
}
    `;

/**
 * __useDeviceCurrentStatusUpdatedSubscription__
 *
 * To run a query within a React component, call `useDeviceCurrentStatusUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeviceCurrentStatusUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceCurrentStatusUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDeviceCurrentStatusUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeviceCurrentStatusUpdatedSubscription, DeviceCurrentStatusUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeviceCurrentStatusUpdatedSubscription, DeviceCurrentStatusUpdatedSubscriptionVariables>(DeviceCurrentStatusUpdatedDocument, options);
      }
export type DeviceCurrentStatusUpdatedSubscriptionHookResult = ReturnType<typeof useDeviceCurrentStatusUpdatedSubscription>;
export type DeviceCurrentStatusUpdatedSubscriptionResult = Apollo.SubscriptionResult<DeviceCurrentStatusUpdatedSubscription>;
export const AlertReceivedDocument = gql`
    subscription AlertReceived {
  alertReceived {
    alertTypeId
    sendTime
    messageId
    imei
    lat
    lon
    address
    value
  }
}
    `;

/**
 * __useAlertReceivedSubscription__
 *
 * To run a query within a React component, call `useAlertReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAlertReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertReceivedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAlertReceivedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AlertReceivedSubscription, AlertReceivedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AlertReceivedSubscription, AlertReceivedSubscriptionVariables>(AlertReceivedDocument, options);
      }
export type AlertReceivedSubscriptionHookResult = ReturnType<typeof useAlertReceivedSubscription>;
export type AlertReceivedSubscriptionResult = Apollo.SubscriptionResult<AlertReceivedSubscription>;
export const GetOrdersDocument = gql`
    query GetOrders {
  orders {
    id
    product {
      name
      id
    }
    customer {
      name
    }
    delivery {
      person {
        name
        image
      }
      device {
        imei
        name
        description
        currentStatus {
          imei
          latitude
          longitude
          speed
        }
      }
      location {
        address
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export function refetchGetOrdersQuery(variables?: GetOrdersQueryVariables) {
      return { query: GetOrdersDocument, variables: variables }
    }
export type GetAllAlertsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAlertsQuery = { __typename?: 'Query', allAlerts: Array<{ __typename?: 'Alert', alertTypeId: number, sendTime?: any | null | undefined, messageId: string, imei?: string | null | undefined, lat?: any | null | undefined, lon?: any | null | undefined, address?: string | null | undefined, value?: string | null | undefined, isRead?: boolean | null | undefined } | null | undefined> };

export type MarkAlertAsReadMutationVariables = Exact<{
  messageId: Scalars['String'];
}>;


export type MarkAlertAsReadMutation = { __typename?: 'Mutation', markAlertAsRead?: { __typename?: 'Alert', alertTypeId: number, sendTime?: any | null | undefined, messageId: string, imei?: string | null | undefined, lat?: any | null | undefined, lon?: any | null | undefined, address?: string | null | undefined, value?: string | null | undefined, isRead?: boolean | null | undefined } | null | undefined };

export type GetBoundariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBoundariesQuery = { __typename?: 'Query', boundaries?: Array<{ __typename?: 'Boundary', boundaryId: string, name?: string | null | undefined, geometry?: any | null | undefined, color?: any | null | undefined } | null | undefined> | null | undefined };

export type GetDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDevicesQuery = { __typename?: 'Query', devices?: Array<{ __typename?: 'Device', imei: string, name?: string | null | undefined, description?: string | null | undefined, currentStatus?: { __typename?: 'DeviceCurrentStatus', imei?: string | null | undefined, latitude?: any | null | undefined, longitude?: any | null | undefined, speed?: number | null | undefined } | null | undefined }> | null | undefined };

export type DeviceCurrentStatusUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DeviceCurrentStatusUpdatedSubscription = { __typename?: 'Subscription', deviceCurrentStatusUpdated?: { __typename?: 'DeviceCurrentStatus', imei?: string | null | undefined, message_id: string, sendTime?: string | null | undefined, latitude?: any | null | undefined, longitude?: any | null | undefined, speed?: number | null | undefined } | null | undefined };

export type AlertReceivedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AlertReceivedSubscription = { __typename?: 'Subscription', alertReceived?: { __typename?: 'Alert', alertTypeId: number, sendTime?: any | null | undefined, messageId: string, imei?: string | null | undefined, lat?: any | null | undefined, lon?: any | null | undefined, address?: string | null | undefined, value?: string | null | undefined } | null | undefined };

export type GetOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = { __typename?: 'Query', orders?: Array<{ __typename?: 'Order', id: string, product: { __typename?: 'Product', name: string, id: string }, customer: { __typename?: 'Customer', name: string }, delivery: { __typename?: 'Delivery', person: { __typename?: 'Person', name: string, image: string }, device?: { __typename?: 'Device', imei: string, name?: string | null | undefined, description?: string | null | undefined, currentStatus?: { __typename?: 'DeviceCurrentStatus', imei?: string | null | undefined, latitude?: any | null | undefined, longitude?: any | null | undefined, speed?: number | null | undefined } | null | undefined } | null | undefined, location: { __typename?: 'Location', address: string, latitude: any, longitude: any } } }> | null | undefined };
