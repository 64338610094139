import { Marker, Popup } from "react-leaflet";
import React from "react";

import svgData from "../../assets/delivery-courier.png";
import L from "leaflet";
import { Device } from "../../generated/graphql";

let icon = L.icon({
  iconUrl: svgData,
  iconSize: [40, 40],
});

const UnusedVehicleMarker = ({ device }: { device: Device }) => {
  if (
    !(
      device &&
      device.currentStatus?.latitude &&
      device.currentStatus.longitude
    )
  ) {
    return null;
  }

  return (
    <Marker
      icon={icon}
      position={[
        device.currentStatus?.latitude,
        device.currentStatus?.longitude,
      ]}
    >
      <Popup>{device.name}</Popup>
    </Marker>
  );
};

export default UnusedVehicleMarker;
