import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./modules/app/App";
import { MyApolloProvider } from "./lib/apollo/apollo";

ReactDOM.render(
  <React.StrictMode>
    <MyApolloProvider>
      <App />
    </MyApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
