import { Order } from "../../generated/graphql";
import { Marker, Popup } from "react-leaflet";
import React from "react";

import svgData from "../../assets/food-delivery.png";
import L from "leaflet";
import { getDistanceFromLatLon } from "../../lib/utils";

let icon = L.icon({
  iconUrl: svgData,
  iconSize: [40, 40],
});

const DeliveryVehicleMarker = ({ order }: { order: Order }) => {
  console.log('order: ', order)
  const device = order.delivery.device;
  if (!device) {
    return null;
  }

  const deviceStatus = order.delivery.device?.currentStatus;

  console.log('deviceStatus lat: ', deviceStatus?.latitude);
  console.log('deviceStatus lon: ', deviceStatus?.longitude);

  if (!deviceStatus?.latitude || !deviceStatus?.longitude) {
    console.log('non existent device status fields: ', deviceStatus);
    return null;
  }

  return (
    <Marker
      icon={icon}
      position={[deviceStatus?.latitude, deviceStatus?.longitude]}
    >
      <Popup>
        Delivery for {order.customer.name} <br />
        Driver: {order.delivery.person.name} <br />
        Distance:
        {getDistanceFromLatLon(
          deviceStatus?.latitude,
          deviceStatus?.longitude,
          order.delivery.location.latitude,
          order.delivery.location.longitude
        ).toFixed(1)}
        miles <br />
        Speed: {(deviceStatus?.speed ?? 0).toFixed(1)} mph
      </Popup>
    </Marker>
  );
};

export default DeliveryVehicleMarker;
