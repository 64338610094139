import React from "react";
import styled from "styled-components";
import { Order } from "../../generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { selectedOrderVar } from "../../lib/apollo/apolloCache";

interface OrderCardParams {
  order: Order;
}

const OrderWrapper = styled.div<{ selected: boolean }>`
  margin: 20px;
  padding: 10px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 30%) !important;
  transition: 200ms;
  background-color: ${({ selected }) => (selected ? "red" : "white")};
  cursor: pointer;
`;

export const OrderCard = ({ order }: OrderCardParams) => {
  const selectedOrder = useReactiveVar(selectedOrderVar);
  const isSelected = selectedOrder === order;
  const selectThisOrder = () => {
    selectedOrderVar(order);
  };

  return (
    <OrderWrapper onClick={selectThisOrder} selected={isSelected}>
      <div>
        {order.product.id}: {order.product.name}
      </div>
      <div>Deliver To: {order.customer.name}</div>
      <div>{order.delivery.location.address}</div>
      <hr />
      <div>Driver: {order.delivery.person.name}</div>
      <div>Vehicle: {order.delivery.device?.name}</div>
    </OrderWrapper>
  );
};
