import React from "react";
import styled from "styled-components";
import { OrderList } from "../../modules/orders/OrderList";
import { DeviceList } from "../../modules/devices/DeviceList";

const SidebarWrapper = styled.div`
  width: 500px;
  height: 100%;
  overflow-y: auto;
`;

const Sidebar = () => {
  return (
    <SidebarWrapper>
      <OrderList />
      <DeviceList />
    </SidebarWrapper>
  );
};

export default Sidebar;
