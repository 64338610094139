import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
  }
  div {
    box-sizing: border-box;
  }
`;
