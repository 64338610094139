import React from "react";
import styled from "styled-components";
import Map from "../../modules/map/Map";
import Sidebar from "../sidebar/Sidebar";
import BottomBar from "../bottom/BottomBar";
import AlertsBox from "../alerts/AlertsBox";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`;

const Main = () => {
  return (
    <MainWrapper>
      <Sidebar />
      <Map />
      <BottomBar />
      <AlertsBox />
    </MainWrapper>
  );
};

export default Main;
