import { gql } from "@apollo/client";

export const GET_ALL_ALERTS = gql`
  query GetAllAlerts {
    allAlerts @client {
      alertTypeId
      sendTime
      messageId
      imei
      lat
      lon
      address
      value
      isRead
    }
  }
`;

export const MARK_ALERT_READ_MUTATION = gql`
  mutation MarkAlertAsRead($messageId: String!) {
    markAlertAsRead(messageId: $messageId) @client {
      alertTypeId
      sendTime
      messageId
      imei
      lat
      lon
      address
      value
      isRead
    }
  }
`;
