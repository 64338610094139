import React from "react";
import { Boundary } from "../../generated/graphql";
import { GeoJSON, Popup } from "react-leaflet";

const BoundaryMarker = ({ boundary }: { boundary: Boundary }) => {
  const data = {
    ...boundary.geometry,
    properties: {
      name: boundary.name,
      popupContent: boundary.name,
    },
  };
  return (
    <GeoJSON
      data={data}
      style={{
        color: boundary.color,
        weight: 3,
        opacity: 0.4,
      }}
    >
      <Popup>{boundary.name}</Popup>
    </GeoJSON>
  );
};

export default BoundaryMarker;
