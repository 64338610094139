import { InMemoryCache, makeVar, ReactiveVar } from "@apollo/client";

import { Maybe, Order } from "../../generated/graphql";

export const selectedOrderVar: ReactiveVar<Maybe<Order>> =
  makeVar<Maybe<Order>>(null);

export const getCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Alert: {
        keyFields: ["messageId"],
        fields: {
          isRead: {
            read() {
              return false;
            },
          },
        },
      },
      Boundary: {
        keyFields: ["boundaryId"],
      },
      Device: {
        keyFields: ["imei"],
      },
      DeviceCurrentStatus: {
        keyFields: ["imei"],
      },
      DeviceStatus: {
        keyFields: ["imei"],
      },
    },
  });
};
