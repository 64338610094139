import React, { useState } from "react";
import styled from "styled-components";
import {
  Alert,
  useAlertReceivedSubscription,
  useGetAllAlertsQuery,
} from "../../generated/graphql";
import { GET_ALL_ALERTS } from "../../graphql/alerts.graphql";

const AlertsBoxWrapper = styled.div<{ hasAlerts: boolean }>`
  position: absolute;
  top: 25px;
  right: 25px;

  z-index: 2;
  transition: 150ms ease-in;
  opacity: ${({ hasAlerts }) => (hasAlerts ? 1 : 0)};
`;

const AlertItemWrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  height: 50px;
  overflow: hidden;
  font-size: 10px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const AlertItem = ({ alert }: { alert: Alert }) => {
  const [isRead, setIsRead] = useState<boolean>(false);
  const markAlertAsRead = () => {
    setIsRead(true);
  };

  if (isRead) {
    return null;
  }

  return (
    <AlertItemWrapper onClick={markAlertAsRead}>
      Alert {alert.alertTypeId} {alert.lat} {alert.lon} {alert.imei}
    </AlertItemWrapper>
  );
};

const AlertsBox = () => {
  useAlertReceivedSubscription({
    onSubscriptionData(data) {
      const alert = data?.subscriptionData?.data?.alertReceived;
      if (!alert) {
        return;
      }
      const existing = data.client.cache.read({
        optimistic: true,
        query: GET_ALL_ALERTS,
        variables: {},
      });
      data.client.cache.writeQuery({
        query: GET_ALL_ALERTS,
        data: {
          allAlerts: [alert, ...(existing?.allAlerts ?? [])],
        },
      });
    },
  });

  const { data } = useGetAllAlertsQuery();

  if (!data) {
    return null;
  }

  return (
    <AlertsBoxWrapper hasAlerts={data.allAlerts.length > 0 ?? false}>
      {data?.allAlerts?.map((alert) => (
        <AlertItem alert={alert as Alert} key={alert?.messageId} />
      ))}
    </AlertsBoxWrapper>
  );
};
export default AlertsBox;
