import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type Query {
    allAlerts: [Alert]!
  }

  extend type Mutation {
    markAlertAsRead(messageId: String!): Alert
  }
`;
