import React from "react";
import styled from "styled-components";
import { useDeviceCurrentStatusUpdatedSubscription } from "../../generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { selectedOrderVar } from "../../lib/apollo/apolloCache";

const BottomBarWrapper = styled.div<{ isShown: boolean }>`
  position: absolute;
  bottom: ${({ isShown }) => (isShown ? 0 : -200)}px;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: white;
  transition: 150ms ease-in all;
`;

const BottomBar = () => {
  useDeviceCurrentStatusUpdatedSubscription();

  const selectedOrder = useReactiveVar(selectedOrderVar);
  const unSelectOrder = () => {
    selectedOrderVar(null);
  };

  return (
    <BottomBarWrapper isShown={!!selectedOrder}>
      {JSON.stringify(selectedOrder)}
      <button onClick={unSelectOrder}> close</button>
    </BottomBarWrapper>
  );
};

export default BottomBar;
