import React from "react";
import styled from "styled-components";
import { GlobalStyle } from "./global.styled";

import Main from "../../containers/main/Main";

const AppWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

function App() {
  return (
    <AppWrapper>
      <GlobalStyle />
      <Main />
    </AppWrapper>
  );
}

export default App;
