import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  Boundary,
  Device,
  Order,
  useGetBoundariesQuery,
  useGetDevicesQuery,
  useGetOrdersQuery,
} from "../../generated/graphql";
import BoundaryMarker from "./Boudary";
import UnusedVehicleMarker from "./UnusedVechileMarker";
import { config } from "../../lib/config";
import OrderElements from "./OrderElements";

const useGetUnusedVehicles = () => {
  const { data: ordersQuery } = useGetOrdersQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: devicesQuery } = useGetDevicesQuery({
    fetchPolicy: "cache-and-network",
  });
  const usedVehicles: string[] =
    ordersQuery?.orders?.map((order) => order.delivery.device?.imei ?? "") ??
    [];

  return (
    devicesQuery?.devices?.filter(
      (device) => !usedVehicles.includes(device.imei)
    ) ?? []
  );
};

const useMapElementsData = () => {
  const { data: ordersQuery } = useGetOrdersQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: boundariesQuery } = useGetBoundariesQuery({
    fetchPolicy: "cache-and-network",
  });
  const unusedVehicles = useGetUnusedVehicles();
  const boundariesWithGeometry =
    boundariesQuery?.boundaries?.filter((b) => b?.geometry !== null) ?? [];

  return {
    orders: ordersQuery?.orders ?? [],
    boundaries: boundariesWithGeometry,
    unusedVehicles,
  };
};

const Map = () => {
  const mapData = useMapElementsData();

  return (
    <MapContainer
      center={[26.2696601, -81.7997423]}
      zoom={16}
      scrollWheelZoom={true}
    >
      <TileLayer
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        id={"mapbox/streets-v11"}
        accessToken={config.mapboxToken}
      />

      {mapData.orders.map((order) => (
        <OrderElements order={order as Order} key={order.id} />
      ))}

      {mapData.unusedVehicles.map((vehicle) => (
        <UnusedVehicleMarker device={vehicle as Device} key={vehicle.imei} />
      ))}

      {mapData.boundaries.map((b, i) => (
        <BoundaryMarker boundary={b as Boundary} key={i} />
      ))}
    </MapContainer>
  );
};

export default Map;
